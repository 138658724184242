// import inst from "../../assets/icons/instagram.svg";
// import face from "../../assets/icons/facebook.svg";
import arrow from "../../assets/icons/contacts_arrow.svg";
import { NavLink } from "react-router-dom";
import {
  FooterCardOne,
  FooterCardThree,
  FooterCardTwo,
  FooterStyled,
} from "./Footer.styled";
import { useSelector } from "react-redux";
import { selectIsLoggedIn } from "../../store/user/selectors";
import { FeedbackModal, LoginModal } from "../../components/modal";

const letters = ["C", "r", "e", "a", "t", "o", "r", "s"];

const Footer = () => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const uppPageHandler = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <footer className="flex flex-col justify-center bg-grey max-w-[1980px] mx-auto">
      <FooterStyled>
        <FooterCardOne>
          <h4 className="salted">Робимо все щоб пришвидшити перемогу</h4>
          <div className="flex flex-col gap-5 md:gap-16 items-center lg:items-start">
            <div className="flex gap-2.5">
              {isLoggedIn ? (
                <NavLink to="/add-request">
                  <button className="button-white" onClick={uppPageHandler}>
                    Створити запит
                  </button>
                </NavLink>
              ) : (
                <LoginModal
                  title={"Створити запит"}
                  cssClass={"button-white p-2"}
                />
              )}
              {isLoggedIn ? (
                <NavLink to="/add-fundraise">
                  <button className="button-black" onClick={uppPageHandler}>
                    Відкрити збір
                  </button>
                </NavLink>
              ) : (
                <LoginModal title={"Відкрити збір"} />
              )}
            </div>
            <p className="flex text-base not-italic font-light items-center text-center lg:text-start">
              * ми працюємо тільки з офіційними запитами від військових частин
              або індивідуальних, з оформленням відповідних документів.
            </p>
          </div>
        </FooterCardOne>
        <FooterCardTwo id="contacts">
          <div className="flex justify-between py-5 lg:p-0">
            <h4 className="salted">Контакти</h4>
            <img src={arrow} alt="arrow" width={"16px"} />
          </div>
          <div className="flex flex-col sms:flex-row gap-2.5 items-center justify-around md:justify-between">
            <div className="flex flex-col gap-[30px]">
              <a
                href="tel:+380988483528"
                className="text-[20px] lg:text-[42px]"
              >
                +38 (098) 848 35 28
              </a>
              <a href="mailto:requestyua@gmail.com" className="text-[20px]">
                requestyua@gmail.com
              </a>
            </div>
            {/* <div className="flex sms:flex-col gap-[20px] justify-start md:items-end">
              <a
                className="social-link"
                href="https://www.instagram.com/"
                aria-label="Instgram"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={inst} alt="instagram" />
              </a>
              <a
                className="social-link"
                href="https://www.facebook.com/"
                aria-label="facebook"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={face} alt="facebook" />
              </a>
            </div> */}
          </div>
        </FooterCardTwo>
        <FooterCardThree>
          <h4 className="text-center">
            Якщо маєте запитання або пропозиції звяжіться з нами
          </h4>
          <div className="flex items-center 2xl:mr-[300px]">
            <FeedbackModal />
          </div>
        </FooterCardThree>
      </FooterStyled>
      <div className="flex flex-wrap justify-center md:justify-between px-8">
        <div className="flex flex-wrap justify-center">
          <NavLink
            className="text-xs p-3 items-center"
            onClick={uppPageHandler}
            to={"/privacy"}
          >
            Політика конфіденційності
          </NavLink>
          <NavLink
            className="text-xs p-3 items-center"
            onClick={uppPageHandler}
            to={"/terms"}
          >
            Угода користувача
          </NavLink>
        </div>
        <div className="flex gap-1 justify-center p-5 waviy">
          <a href="/creators" className="flex self-center text-xs">
            {letters.map((letter, index) => (
              <span key={index} style={{ "--i": index + 1 }}>
                {letter}
              </span>
            ))}
          </a>
        </div>
      </div>
      <div className="flex justify-center p-4 text-sm">© 2024 Requesty</div>
    </footer>
  );
};

export default Footer;
