import AddFund from "./AddFundModal";
import AddInitiative from "./AddInitiativeModal";
import AddPetition from "./AddPetitionModal";
import CloseFundraise from "./CloseFundraiseModal";
import Confirmation from "./ConfirmationModal";
import DeleteAccount from "./DeleteAccountModal";
import EmailLogin from "./EmailLoginModal";
import EmailRegister from "./EmailRegisterModal";
import Feedback from "./FeedbackModal";
import FirstLogin from "./FirstLoginModal";
import HowItWorks from "./HowItWorksModal";
import Login from "./LoginModal";
import Report from "./ReportModal";
import VerifyMfa from "./VerifyMfaModal";
import VolunteerForm from "./VolunteerFormModal";

export const AddFundModal = AddFund;
export const AddInitiativeModal = AddInitiative;
export const AddPetitionModal = AddPetition;
export const CloseFundraiseModal = CloseFundraise;
export const ConfirmationModal = Confirmation;
export const DeleteAccountModal = DeleteAccount;
export const EmailLoginModal = EmailLogin;
export const EmailRegisterModal = EmailRegister;
export const FeedbackModal = Feedback;
export const FirstLoginModal = FirstLogin;
export const HowItWorksModal = HowItWorks;
export const LoginModal = Login;
export const ReportModal = Report;
export const VerifyMfaModal = VerifyMfa;
export const VolunteerFormModal = VolunteerForm;
