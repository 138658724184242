import * as React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "@splidejs/react-splide/css/sea-green";
import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import mail from "../../assets/icons/EmailLogo.svg";
import { emailRegister } from "../../store/user/operations";
import { EmailLoginModal } from ".";

const EmailRegisterModal = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const errorToast = (message) =>
    toast.error(message, {
      position: "top-center",
    });

  const [formDataValues, setFormDataValues] = useState({
    name: "",
    email: "",
    password: "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormDataValues((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await dispatch(emailRegister(formDataValues)).unwrap();
      if (result) {
        setFormDataValues({
          name: "",
          email: "",
          password: "",
        });
        document.getElementById("emailRegisterForm").reset();
        setIsInfoModalOpen(true);
        setOpen(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    } catch (error) {
      errorToast(error.message || "An error occurred");
    }
  };

  return (
    <div>
      <button className="button-white" onClick={handleOpen}>
        Register with email
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="absolute flex flex-col items-center gap-3 top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2 w-[400] rounded-[8px] p-6 bg-white">
          <h1 className="flex self-center text-xl lg:text-5xl">
            Зареєструватись
          </h1>
          <button
            type="button"
            onClick={handleClose}
            className="absolute top-2 right-3"
          >
            <CloseIcon className="opacity-60 hover:opacity-100 hover:text-[#CA563F] [transition:0.3s_ease]" />
          </button>
          <form
            id="emailRegisterForm"
            className="flex flex-col gap-4 p-5 w-[300px] md:w-[500px] lg:w-[1000px]"
            onSubmit={handleSubmit}
          >
            <TextField
              type="text"
              label="Ваше ім'я"
              variant="outlined"
              required
              size="small"
              name="name"
              onChange={handleInputChange}
            />
            <TextField
              type="text"
              label="Ваш email"
              variant="outlined"
              required
              size="small"
              name="email"
              onChange={handleInputChange}
            />
            <TextField
              type="text"
              label="Ваш пароль"
              variant="outlined"
              required
              size="small"
              name="password"
              onChange={handleInputChange}
            />
            <div className="self-center">
              <button type="submit" className="button-black">
                Увійти
              </button>
            </div>
          </form>
        </Box>
      </Modal>
      <Modal open={isInfoModalOpen} onClose={() => setIsInfoModalOpen(false)}>
        <Box className="absolute flex flex-col justify-center items-center top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2 w-[400] rounded-[8px] p-2 bg-grey">
          <h2 className="text-lg pr-5">
            Після підтвердження реєстрації на пошті ви зможете увійти на нашу
            платформу
          </h2>
          <div className="flex gap-6 items-center justify-center cursor-pointer p-4">
            <img src={mail} alt="email-icon" />
            <EmailLoginModal />
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default EmailRegisterModal;
