import { Suspense, lazy, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import SharedLayout from "./layout/sharedLayout/SharedLayout";
import { useDispatch } from "react-redux";
import { currentUser } from "./store/user/operations";
import RedirectPage from "./pages/redirectPage";
import { PrivateRoute } from "./routes/PrivateRoute";

const HomePage = lazy(() => import("./pages/homePage"));
const FundsPage = lazy(() => import("./pages/fundsPage"));
const FundraisesPage = lazy(() => import("./pages/fundraisesPage"));
const InitiativesPage = lazy(() => import("./pages/initiativesPage"));
const PetitionsPage = lazy(() => import("./pages/petitionsPage"));
const ProfilePage = lazy(() => import("./pages/profilePage"));
const MyRequests = lazy(() => import("./pages/myRequestsPage"));
const MyFundraises = lazy(() => import("./pages/myFundraisesPage"));
const RequestsPage = lazy(() => import("./pages/requestsPage"));
const AddRequest = lazy(() => import("./pages/addRequestPage"));
const AddPetition = lazy(() => import("./pages/addPetitionPage"));
const AddInitiative = lazy(() => import("./pages/addInitiativePage"));
const AddFund = lazy(() => import("./pages/addFundPage"));
const OpenFundraise = lazy(() => import("./pages/openFundraisePage"));
const AddFundraise = lazy(() => import("./pages/addFundraisePage"));
const FundraisePage = lazy(() => import("./pages/fundraisePage"));
const SavedFundraisesPage = lazy(() => import("./pages/savedFundraisesPage"));
const RequestPage = lazy(() => import("./pages/requestPage"));
const AdminPage = lazy(() => import("./pages/adminPage"));
const VolunteersPage = lazy(() => import("./pages/volunteersPage"));
const Categories = lazy(() => import("./components/categoriesTabs"))
const CreatorsPage = lazy(() => import("./pages/creatorsPage"))
const PrivacyPage = lazy(() => import("./pages/privacyPage"))
const TermsOfUsePage = lazy(() => import("./pages/termsOfUsePage"))

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(currentUser());
  }, [dispatch]);
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route path="/" element={<SharedLayout />}>
          <Route
            path="/:accessToken/:refreshToken"
            element={<RedirectPage />}
          ></Route>
          <Route path="/" element={<HomePage />}></Route>
          <Route path="/fundraises" element={<FundraisesPage />}>
            <Route element={<Categories />} path=":category" />
          </Route>
          <Route path="/favorites" element={<SavedFundraisesPage />}>
            <Route element={<Categories />} path=":category" />
          </Route>
          <Route path="/fundraise/:id" element={<FundraisePage />}></Route>
          <Route path="/funds" element={<FundsPage />}></Route>
          <Route path="/initiatives" element={<InitiativesPage />}></Route>
          <Route path="/petitions" element={<PetitionsPage />}></Route>
          <Route path="/creators" element={<CreatorsPage />}></Route>
          <Route path="/privacy" element={<PrivacyPage />}></Route>
          <Route path="/terms" element={<TermsOfUsePage />}></Route>
          <Route
            path="/add-request"
            element={
              <PrivateRoute
                redirectTo="/"
                element={<AddRequest />}
                allowedRoles={["DEFENDER"]}
              />
            }
          />
          <Route path="/my-requests" element={
            <PrivateRoute
              redirectTo="/"
              element={<MyRequests />}
              allowedRoles={["DEFENDER"]}
            />
          }></Route>
          <Route
            path="/petitions/add-petition"
            element={
              <PrivateRoute
                redirectTo="/"
                element={<AddPetition />}
                allowedRoles={["DEFENDER", "USER", "ADMIN", "VOLUNTEER", "MODERATOR"]}
              />
            }
          ></Route>
          <Route
            path="/initiatives/add-initiative"
            element={
              <PrivateRoute
                redirectTo="/"
                element={<AddInitiative />}
                allowedRoles={["DEFENDER", "USER", "ADMIN", "VOLUNTEER", "MODERATOR"]}
              />
            }
          ></Route>
          <Route
            path="/profile/:id"
            element={
              <PrivateRoute
                redirectTo="/"
                element={<ProfilePage />}
                allowedRoles={["DEFENDER", "USER", "ADMIN", "VOLUNTEER", "MODERATOR"]}
              />
            }
          ></Route>
          <Route path="/funds/add-fund" element={<PrivateRoute
            redirectTo="/"
            element={<AddFund />}
            allowedRoles={["DEFENDER", "USER", "ADMIN", "VOLUNTEER", "MODERATOR"]}
          />}></Route>
          <Route path="/my-fundraises" element={<PrivateRoute
            redirectTo="/"
            element={<MyFundraises />}
            allowedRoles={["VOLUNTEER"]}
          />}></Route>
          <Route path="/open-fundraise/:id" element={<PrivateRoute
            redirectTo="/"
            element={<OpenFundraise />}
            allowedRoles={["VOLUNTEER"]}
          />}></Route>
          <Route path="/add-fundraise" element={<PrivateRoute
            redirectTo="/"
            element={<AddFundraise />}
            allowedRoles={["VOLUNTEER"]}
          />}></Route>
          <Route path="/volunteers" element={<PrivateRoute
            redirectTo="/"
            element={<VolunteersPage />}
            allowedRoles={["VOLUNTEER", "MODERATOR"]}
          />}>
            <Route element={<Categories />} path=":category" />
          </Route>
          <Route path="/request/:id" element={<PrivateRoute
            redirectTo="/"
            element={<RequestPage />}
            allowedRoles={["ADMIN", "VOLUNTEER", "MODERATOR"]}
          />}></Route>
          <Route path="/requests" element={<PrivateRoute
            redirectTo="/"
            element={<RequestsPage />}
            allowedRoles={["ADMIN", "VOLUNTEER", "MODERATOR"]}
          />}></Route>
          <Route path="/admin" element={<PrivateRoute
            redirectTo="/"
            element={<AdminPage />}
            allowedRoles={["ADMIN"]}
          />}></Route>
        </Route>
      </Routes>
    </Suspense>
  );
}

function Loading() {
  return (
    <div className="flex justify-center items-center sm:p-28 xl:p-[200px]">

      <span className="loader"></span>

    </div>
  );
}
export default App;
