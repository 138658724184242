import { createSlice } from '@reduxjs/toolkit'
import { fetchAllUsers, fetchAllPetitions, fetchAllFunds, fetchAllFundraises, fetchAllRequests, fetchAllInitiatives, updateUserRole, moderateRequest, moderateFundraise, moderateVolunteer } from './operations';
const initialState = {
    users: [],
    petitions: [],
    initiatives: [],
    funds: [],
    fundraises: [],
    requests: [],
    loading: false,
    error: null,
    count: 0,
    totalPages: 0,
    currentPage: 1,
}

export const adminSlice = createSlice({
    name: 'admin',
    initialState,
    extraReducers: builder => {
        builder
            .addCase(fetchAllUsers.fulfilled, (state, { payload }) => {
                state.users = payload;
                state.loading = false;
                state.totalPages = Math.ceil(payload.count / 20);
                state.count = payload.count
            })
            .addCase(fetchAllUsers.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchAllUsers.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
            })
            .addCase(fetchAllPetitions.fulfilled, (state, { payload }) => {
                state.petitions = payload;
                state.loading = false;
                state.totalPages = Math.ceil(payload.count / 20);
                state.count = payload.count
            })
            .addCase(fetchAllPetitions.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchAllPetitions.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
            })
            .addCase(fetchAllFunds.fulfilled, (state, { payload }) => {
                state.funds = payload;
                state.loading = false;
                state.totalPages = Math.ceil(payload.count / 20);
                state.count = payload.count
            })
            .addCase(fetchAllFunds.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchAllFunds.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
            })
            .addCase(fetchAllFundraises.fulfilled, (state, { payload }) => {
                state.fundraises = payload;
                state.loading = false;
                state.totalPages = Math.ceil(payload.count / 20);
                state.count = payload.count
            })
            .addCase(fetchAllFundraises.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchAllFundraises.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
            })
            .addCase(fetchAllRequests.fulfilled, (state, { payload }) => {
                state.requests = payload;
                state.loading = false;
                state.totalPages = Math.ceil(payload.count / 20);
                state.count = payload.count
            })
            .addCase(fetchAllRequests.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchAllRequests.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
            })
            .addCase(fetchAllInitiatives.fulfilled, (state, { payload }) => {
                state.initiatives = payload;
                state.loading = false;
                state.totalPages = Math.ceil(payload.count / 20);
                state.count = payload.count
            })
            .addCase(fetchAllInitiatives.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchAllInitiatives.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
            })
            .addCase(updateUserRole.fulfilled, (state, { payload }) => {
                state.users = state.users.map(user =>
                    user.email === payload.data.email
                        ? { ...user, role: payload.data.role }
                        : user
                );
                state.loading = false;

            })
            .addCase(updateUserRole.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateUserRole.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
            })
            .addCase(moderateRequest.fulfilled, (state, { payload }) => {
                state.loading = false;
            })
            .addCase(moderateRequest.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(moderateRequest.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
            })
            .addCase(moderateFundraise.fulfilled, (state, { payload }) => {
                state.loading = false;
            })
            .addCase(moderateFundraise.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(moderateFundraise.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
            })
            .addCase(moderateVolunteer.fulfilled, (state, { payload }) => {
                state.loading = false;
            })
            .addCase(moderateVolunteer.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(moderateVolunteer.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
            })
    },
})
export default adminSlice.reducer