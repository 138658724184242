import { useNavigate, NavLink } from "react-router-dom";
import { HeaderStyled } from "./Header.styled";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import BurgerMenu from "../../components/burgerMenu";
import {
  selectIsLoggedIn,
  selectCurrentUserRole,
  selectCurrentUser,
} from "../../store/user/selectors";
import logo from "../../assets/icons/LOGO green.svg";
import DropDown from "../../components/profileDropDown";
import { LoginModal } from "../../components/modal";

const Header = () => {
  const isMobile = useMediaQuery({ maxWidth: 874 });
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const role = useSelector(selectCurrentUserRole);
  const user = useSelector(selectCurrentUser);
  const navigate = useNavigate();

  function refreshPage() {
    navigate(`/fundraises/`);
    window.location.reload(false);
  }
  return (
    <HeaderStyled>
      <div className="flex">
        <NavLink to="/">
          <img className="swing" src={logo} alt="logo" />
        </NavLink>
      </div>
      {isMobile ? (
        <BurgerMenu />
      ) : (
        <div className="flex">
          <ul className="flex gap-8 pr-8 items-center border-r-2 border-grey">
            <NavLink to="/fundraises" onClick={refreshPage}>
              Збори
            </NavLink>
            <NavLink to="/funds">Фонди</NavLink>
            <NavLink to="/petitions">Петиції</NavLink>
            <NavLink to="/initiatives">Ініціативи</NavLink>
          </ul>
          <ul className="flex gap-8 pl-8 items-center">
            {isLoggedIn || <LoginModal />}
            {isLoggedIn && (
              <>
                {role === "USER" && (
                  <NavLink to="/favorites">Збережене</NavLink>
                )}
                {role === "DEFENDER" && (
                  <NavLink to="/my-requests">Мої запити</NavLink>
                )}
                {role === "VOLUNTEER" && user.isVolunteerChecked && (
                  <>
                    <NavLink to="/volunteers">Волонтери</NavLink>
                    <NavLink to="/requests">Запити</NavLink>
                    <NavLink to="/my-fundraises">Мої збори</NavLink>
                  </>
                )}
                {role === "ADMIN" && (
                  <>
                    <NavLink to="/admin">Кабінет</NavLink>
                  </>
                )}
                <DropDown />
              </>
            )}
          </ul>
        </div>
      )}
    </HeaderStyled>
  );
};
export default Header;
