import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { TextField } from "@mui/material";
import { useState } from "react";
import { addReport } from "../../store/fundraise/operations";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "@splidejs/react-splide/css/sea-green";
import CloseIcon from "@mui/icons-material/Close";

const ReportModal = ({ id }) => {
  const errorToast = (message) =>
    toast.error(message, {
      position: "top-center",
    });
  const successToast = () =>
    toast("Надіслано", {
      position: "top-center",
    });

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [formDataValues, setFormDataValues] = useState({
    reportPhotoIMG: "",
    reportDescr: "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "reportPhotoIMG") {
      const file = e.target.files[0];
      setImages((prevImages) => [...prevImages, { name, file }]);
    }
    setFormDataValues((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    Object.entries(formDataValues).forEach(([key, value]) => {
      formData.append(key, value);
    });

    images.forEach((image) => {
      formData.append(image.name, image.file);
    });
    try {
      const result = dispatch(addReport({ id, body: formData }));
      if (result) {
        setIsLoading(false);
        setFormDataValues({
          reportPhotoIMG: "",
          reportDescr: "",
        });
        successToast();
        setOpen(false);
        document.getElementById("closeForm").reset();
      }
    } catch (error) {
      errorToast(error.response.data.message);
    }
  };

  return (
    <div>
      <button className="button-black" onClick={handleOpen}>
        Оформити звіт
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="absolute flex flex-col items-center gap-3 top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2 w-[400] rounded-[8px] p-6 bg-white">
          <h2 id="modal-modal-title" className="pb-6 text-xl">
            Оформлюємо звіт
          </h2>
          <button
            type="button"
            onClick={handleClose}
            className="absolute top-2 right-3"
          >
            <CloseIcon className="opacity-60 hover:opacity-100 hover:text-[#CA563F] [transition:0.3s_ease]" />
          </button>
          <form
            id="closeForm"
            className="flex flex-col gap-4"
            onSubmit={handleSubmit}
          >
            <div className="flex flex-col items-start gap-2 ">
              <div className="flex flex-col gap-2 w-full">
                <div className="flex justify-start items-center gap-2 w-full">
                  <h2>Додайте декілька фото</h2>
                </div>
                <TextField
                  type="file"
                  required
                  variant="outlined"
                  size="small"
                  name="reportPhotoIMG"
                  onChange={handleInputChange}
                />
                <TextField
                  type="file"
                  variant="outlined"
                  size="small"
                  name="reportPhotoIMG"
                  onChange={handleInputChange}
                />
                <TextField
                  type="file"
                  variant="outlined"
                  size="small"
                  name="reportPhotoIMG"
                  onChange={handleInputChange}
                />
                <div className="flex justify-start items-center gap-2 w-full">
                  <h2>Додайте опис</h2>
                </div>
                <TextField
                  type="text"
                  required
                  multiline
                  rows={5}
                  variant="outlined"
                  size="small"
                  name="reportDescr"
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className=" flex gap-4 self-center">
              {isLoading ? (
                <button
                  type="submit"
                  className="button-black self-center pointer-events-none"
                >
                  <div className="flex space-x-2 justify-center items-center">
                    <div className="h-4 w-4 bg-grey rounded-full animate-bounce [animation-delay:-0.3s]"></div>
                    <div className="h-4 w-4 bg-grey rounded-full animate-bounce [animation-delay:-0.15s]"></div>
                    <div className="h-4 w-4 bg-grey rounded-full animate-bounce"></div>
                  </div>
                  {isLoading || "Надіслати"}
                </button>
              ) : (
                <button type="submit" className="button-black">
                  Надіслати
                </button>
              )}
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default ReportModal;
