import { createSlice } from "@reduxjs/toolkit";
import {
  logOut,
  currentUser,
  updateUser,
  updateVolunteer,
  upgradeUserToDefender,
  upgradeUserToVolunteer,
  deleteAccount,
  mfaVerify,
  emailLogin,
  mfaGenerate,
  verifyMfa,
  emailRegister,
} from "./operations";

const initialState = {
  user: {
    id: null,
    email: null,
    name: null,
    avatarIMG: null,
    role: null,
    isNewUser: null,
    isMfaEnable: null,
    qrCodeUrl: null,
    secret: null,
    recoveryCodes: null,
    volunteerInfo: {
      specialization: [],
      phone: "",
      telegram: "",
      fbUrl: "",
    },
  },
  error: null,
  isRefreshing: false,
  isLoggedIn: false,
  authError: null,
  updateUserDataLoading: false,
  toggleFavoriteLoading: false,
  loading: false,
  message: "",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(logOut.fulfilled, (state) => {
        state.user = {
          id: null,
          email: null,
          name: null,
          avatarIMG: null,
          role: null,
          accessToken: "",
          refreshToken: "",
          isMfaEnable: null,
          volunteerInfo: {
            specialization: [],
            phone: "",
            telegram: "",
            fbUrl: "",
          },
        };
        state.isLoggedIn = false;
      })
      .addCase(currentUser.pending, (state) => {
        state.isRefreshing = true;
      })
      .addCase(currentUser.fulfilled, (state, action) => {
        state.user = action.payload;
        state.isLoggedIn = true;
        state.isRefreshing = false;
      })
      .addCase(currentUser.rejected, (state) => {
        state.user = {
          id: null,
          email: null,
          name: null,
          avatarIMG: null,
          role: null,
          volunteerInfo: {
            specialization: [],
            phone: "",
            telegram: "",
            fbUrl: "",
          },
        };
        state.isRefreshing = false;
      })
      .addCase(updateUser.pending, (state) => {
        state.isRefreshing = true;
      })
      .addCase(updateUser.fulfilled, (state, { payload }) => {
        state.user = payload;
        state.isLoggedIn = true;
        state.isRefreshing = false;
      })
      .addCase(updateUser.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(updateVolunteer.pending, (state) => {
        state.isRefreshing = true;
      })
      .addCase(updateVolunteer.fulfilled, (state, { payload }) => {
        state.user = payload;
        state.isLoggedIn = true;
        state.isRefreshing = false;
      })
      .addCase(updateVolunteer.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(upgradeUserToDefender.pending, (state) => {
        state.isRefreshing = true;
      })
      .addCase(upgradeUserToDefender.fulfilled, (state, { payload }) => {
        state.user = payload;
        state.isLoggedIn = true;
        state.isRefreshing = false;
      })
      .addCase(upgradeUserToDefender.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(upgradeUserToVolunteer.pending, (state) => {
        state.isRefreshing = true;
      })
      .addCase(upgradeUserToVolunteer.fulfilled, (state, { payload }) => {
        state.user = payload;
        state.isLoggedIn = true;
        state.isRefreshing = false;
      })
      .addCase(upgradeUserToVolunteer.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(deleteAccount.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteAccount.fulfilled, (state, { payload }) => {
        state.message = payload;
        state.loading = false;
      })
      .addCase(deleteAccount.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(emailLogin.pending, (state) => {
        state.loading = true;
      })
      .addCase(emailLogin.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(emailLogin.fulfilled, (state, { payload }) => {
        state.user = payload;
        state.loading = false;
        state.isLoggedIn = true;
        state.isRefreshing = false;
      })
      .addCase(mfaVerify.pending, (state) => {
        state.loading = true;
      })
      .addCase(mfaVerify.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(mfaVerify.fulfilled, (state, { payload }) => {
        state.user = payload;
        state.loading = false;
        state.isLoggedIn = true;
        state.isRefreshing = false;
      })
      .addCase(mfaGenerate.pending, (state) => {
        state.loading = true;
      })
      .addCase(mfaGenerate.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(mfaGenerate.fulfilled, (state, { payload }) => {
        state.user.qrCodeUrl = payload.qrCodeUrl;
        state.user.secret = payload.secret;
        state.loading = false;
      })
      .addCase(verifyMfa.pending, (state) => {
        state.loading = true;
      })
      .addCase(verifyMfa.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(verifyMfa.fulfilled, (state, { payload }) => {
        state.user.isMfaEnable = payload.isMfaEnable;
        state.user.recoveryCodes = payload.recoveryCodes;
        state.loading = false;
      })
      .addCase(emailRegister.pending, (state) => {
        state.loading = true;
      })
      .addCase(emailRegister.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(emailRegister.fulfilled, (state, { payload }) => {
        state.message = payload;
        state.loading = false;
      });
  },
});

export const userReducer = userSlice.reducer;
