import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "react-toastify/dist/ReactToastify.css";
import done from "../../assets/icons/DoneMark.svg";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css/sea-green";
import CloseIcon from "@mui/icons-material/Close";

const HowItWorksModal = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className="self-center">
      <button className="button-white" onClick={handleOpen}>
        Як це працює?
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="absolute flex flex-col justify-center items-center top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2 w-[400] rounded-[8px] p-2 bg-grey">
          <h2 className="text-lg pr-5">Як це працює</h2>
          <button
            type="button"
            onClick={handleClose}
            className="absolute top-2 right-3"
          >
            <CloseIcon className="opacity-60 hover:opacity-100 hover:text-[#CA563F] [transition:0.3s_ease]" />
          </button>
          <Splide
            aria-label="how it works"
            className="w-[400px] bg-grey rounded-[20px]"
          >
            <SplideSlide className="flex justify-center p-2">
              <div className="flex flex-col gap-4 p-4 bg-white rounded-2xl text-xs sm:w-full smx:w-fit">
                <div className="flex justify-between items-center">
                  <span className="opacity-50">01</span>
                  <img src={done} alt="done-icon" />
                </div>
                <span>Військовий створює запит</span>
              </div>
            </SplideSlide>
            <SplideSlide className="flex justify-center p-2">
              <div className="flex flex-col gap-4 p-4 bg-white rounded-2xl text-xs sm:w-full smx:w-fit">
                <div className="flex justify-between items-center">
                  <span className="opacity-50">02</span>
                  <img src={done} alt="done-icon" />
                </div>
                <span>
                  Волонтер знаходить цей запит і відгукується на нього
                </span>
              </div>
            </SplideSlide>
            <SplideSlide className="flex justify-center p-2">
              <div className="flex flex-col gap-4 p-4 bg-white rounded-2xl text-xs sm:w-full smx:w-fit">
                <div className="flex justify-between items-center">
                  <span className="opacity-50">03</span>
                  <img src={done} alt="done-icon" />
                </div>
                <span>Вони обоє підтверджують співпрацю</span>
              </div>
            </SplideSlide>
            <SplideSlide className="flex justify-center p-2">
              <div className="flex flex-col gap-4 p-4 bg-white rounded-2xl text-xs sm:w-full smx:w-fit">
                <div className="flex justify-between items-center">
                  <span className="opacity-50">04</span>
                  <img src={done} alt="done-icon" />
                </div>
                <span>Волонтер відкриває збір</span>
              </div>
            </SplideSlide>
            <SplideSlide className="flex justify-center p-2">
              <div className="flex flex-col gap-4 p-4 bg-white rounded-2xl text-xs sm:w-full smx:w-fit">
                <div className="flex justify-between items-center">
                  <span className="opacity-50">05</span>
                  <img src={done} alt="done-icon" />
                </div>
                <span>Донатери долучаються</span>
              </div>
            </SplideSlide>
            <SplideSlide className="flex justify-center p-2">
              <div className="flex flex-col gap-4 p-4 bg-white rounded-2xl text-xs sm:w-full smx:w-fit">
                <div className="flex justify-between items-center">
                  <span className="opacity-50">06</span>
                  <img src={done} alt="done-icon" />
                </div>
                <span>Кошти зібрано - волонтер закуповує та відправляє</span>
              </div>
            </SplideSlide>
            <SplideSlide className="flex justify-center p-2">
              <div className="flex flex-col gap-4 p-4 bg-white rounded-2xl text-xs sm:w-full smx:w-fit">
                <div className="flex justify-between items-center">
                  <span className="opacity-50">07</span>
                  <img src={done} alt="done-icon" />
                </div>
                <span>Військовий отримує та підтверджує отримання</span>
              </div>
            </SplideSlide>
            <SplideSlide className="flex justify-center p-2">
              <div className="flex flex-col gap-4 p-4 bg-white rounded-2xl text-xs sm:w-full smx:w-fit">
                <div className="flex justify-between items-center">
                  <span className="opacity-50">08</span>
                  <img src={done} alt="done-icon" />
                </div>
                <span>Всі тішаться - русня вмирає</span>
              </div>
            </SplideSlide>
          </Splide>
        </Box>
      </Modal>
    </div>
  );
};

export default HowItWorksModal;
