import styled from "@emotion/styled";
import { colors, mediaSizes } from "../../components/const";
export const FooterStyled = styled.div`
  display: flex;
  flex-direction: column;
  background: ${colors.mainDark};
  gap: 15px;
  @media screen and (min-width: ${mediaSizes.laptop}) {
    padding: 0 15px 24px;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: auto;
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
`;
export const FooterCardOne = styled.div`
  background: ${colors.mainWhite};
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 30px 10px;
  gap: 20px;
  @media screen and (min-width: ${mediaSizes.laptop}) {
    align-items: start;
    padding: 30px;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: 2;
    grid-column-end: 2;
  }
  @media screen and (min-width: ${mediaSizes.desktop}) {
    align-items: start;
    padding: 60px;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: 2;
    grid-column-end: 2;
  }
`;
export const FooterCardTwo = styled.div`
  background: ${colors.mainWhite};
  font-feature-settings: "salt" on;
  border-radius: 16px;
  padding: 30px 10px;
  @media screen and (min-width: ${mediaSizes.laptop}) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 40px;
    padding: 30px;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: 2;
    grid-column-end: 3;
  }
  @media screen and (min-width: ${mediaSizes.desktop}) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 115px;
    padding: 60px;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: 2;
    grid-column-end: 3;
  }
`;
export const FooterCardThree = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: ${colors.mainWhite};
  border-radius: 16px;
  padding: 30px 10px;
  gap: 20px;
  @media screen and (min-width: ${mediaSizes.laptop}) {
    flex-direction: row;
    align-items: center;
    width: auto;
    padding: 30px;
    gap: 120px;
    grid-row-start: 2;
    grid-column-start: 1;
    grid-row-end: 3;
    grid-column-end: 3;
  }
  @media screen and (min-width: ${mediaSizes.desktop}) {
    flex-direction: row;
    align-items: center;
    width: auto;
    padding: 20px 60px;
    gap: 320px;
    grid-row-start: 2;
    grid-column-start: 1;
    grid-row-end: 3;
    grid-column-end: 3;
  }
`;
