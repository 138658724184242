import { configureStore } from "@reduxjs/toolkit";
import storage from 'redux-persist/lib/storage';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import petitions from "./petitions/slice";
import initiatives from "./initiatives/slice";
import funds from "./funds/slice";
import categories from "./categories/slice";
import requests from "./requests/slice"
import fundraises from "./fundraise/slice"
import admin from "./admin/slice"
import volunteers from "./volunteers/slice"
import { userReducer } from './user/slice';

const authPersistConfig = {
  key: 'auth',
  storage,
  whitelist: [], // 'token'
};

export const store = configureStore({
  reducer: {
    auth: persistReducer(authPersistConfig, userReducer),
    petitions,
    initiatives,
    funds,
    categories,
    requests,
    fundraises,
    admin,
    volunteers,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
export const persistor = persistStore(store);