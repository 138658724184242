import instance from '../user/operations';
import { createAsyncThunk } from '@reduxjs/toolkit';
// -----------------------GET-----------------------------

export const fetchAllUsers = createAsyncThunk(
    "admin/allUsers",
    async (page, { rejectWithValue, getState }) => {
        try {
            const accessToken = localStorage.getItem('accessToken')
            if (!accessToken) {
                return rejectWithValue("Unable to fetch user");
            }
            const result = await instance.get(`/admin/users?page=${page}&limit=100`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            return result.data.data;
        } catch ({ response }) {
            const { status, data } = response;
            const error = {
                status,
                message: data.message,
            };
            return rejectWithValue(error);
        }
    }
);
export const fetchAllPetitions = createAsyncThunk(
    "admin/allPetitions",
    async (page, { rejectWithValue, getState }) => {
        try {
            const accessToken = localStorage.getItem('accessToken')
            if (!accessToken) {
                return rejectWithValue("Unable to fetch petitions");
            }
            const result = await instance.get(`/admin/petitions?page=${page}&limit=100`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            return result.data.data;
        } catch ({ response }) {
            const { status, data } = response;
            const error = {
                status,
                message: data.message,
            };
            return rejectWithValue(error);
        }
    }
);
export const fetchAllFunds = createAsyncThunk(
    "admin/allFunds",
    async (page, { rejectWithValue, getState }) => {
        try {
            const accessToken = localStorage.getItem('accessToken')
            if (!accessToken) {
                return rejectWithValue("Unable to fetch funds");
            }
            const result = await instance.get(`/admin/funds?page=${page}&limit=100`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            return result.data.data;
        } catch ({ response }) {
            const { status, data } = response;
            const error = {
                status,
                message: data.message,
            };
            return rejectWithValue(error);
        }
    }
);
export const fetchAllFundraises = createAsyncThunk(
    "admin/allFundraises",
    async (page, { rejectWithValue, getState }) => {
        try {
            const accessToken = localStorage.getItem('accessToken')
            if (!accessToken) {
                return rejectWithValue("Unable to fetch fundraises");
            }
            const result = await instance.get(`/admin/fundraisings/all?page=${page}&limit=100`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            return result.data.data;
        } catch ({ response }) {
            const { status, data } = response;
            const error = {
                status,
                message: data.message,
            };
            return rejectWithValue(error);
        }
    }
);
export const fetchAllRequests = createAsyncThunk(
    "admin/allRequests",
    async (page, { rejectWithValue, getState }) => {
        try {
            const accessToken = localStorage.getItem('accessToken')
            if (!accessToken) {
                return rejectWithValue("Unable to fetch requests");
            }
            const result = await instance.get(`/admin/requests/all?page=${page}&limit=100`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            return result.data.data;
        } catch ({ response }) {
            const { status, data } = response;
            const error = {
                status,
                message: data.message,
            };
            return rejectWithValue(error);
        }
    }
);
export const fetchAllInitiatives = createAsyncThunk(
    "admin/allInitiatives",
    async (page, { rejectWithValue, getState }) => {
        try {
            const accessToken = localStorage.getItem('accessToken')
            if (!accessToken) {
                return rejectWithValue("Unable to fetch initiatives");
            }
            const result = await instance.get(`/admin/initiatives?page=${page}&limit=100`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            return result.data.data;
        } catch ({ response }) {
            const { status, data } = response;
            const error = {
                status,
                message: data.message,
            };
            return rejectWithValue(error);
        }
    }
);

// ------------------------PATCH--------------------------

export const updateUserRole = createAsyncThunk(
    'admin/updateUserRole',
    async ({ id, role }, thunkApi) => {
        try {
            const response = await instance.patch(`/admin/update-user-role/${id}`, { role });
            return response.data;
        } catch (e) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);
export const moderateRequest = createAsyncThunk(
    'admin/moderateRequest',
    async ({ id, requestCurrentStatus }, thunkApi) => {
        try {
            const response = await instance.patch(`/admin/moderate-request/${id}`, { requestCurrentStatus });
            return response.data;
        } catch (e) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);
export const moderateFundraise = createAsyncThunk(
    'admin/moderateFundraise',
    async ({ id, status }, thunkApi) => {
        try {
            const response = await instance.patch(`/admin/fundraisings/status/${id}`, { status });
            return response.data;
        } catch (e) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);
export const moderateVolunteer = createAsyncThunk(
    'admin/moderateVolunteer',
    async ({ id, isVolunteerChecked }, thunkApi) => {
        try {
            const response = await instance.patch(`/admin/check-volunteer/${id}`, { isVolunteerChecked });
            return response.data;
        } catch (e) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);
export const banUser = createAsyncThunk(
    'admin/moderateVolunteer',
    async ({ id }, thunkApi) => {
        try {
            const response = await instance.patch(`/admin/ban-user/${id}`);
            return response.data;
        } catch (e) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

// -----------------------DELETE--------------------------

export const deleteRequest = createAsyncThunk(
    'admin/deleteRequest',
    async (id, thunkApi) => {
        try {
            await instance.delete(`/admin/delete-request/${id}`);

        } catch (e) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);
export const deleteFundraise = createAsyncThunk(
    'admin/deleteFundraise',
    async ({ _id, isNeedDeleteRequest,
        requestCurrentStatus }, thunkApi) => {
        try {
            await instance.delete(`/admin/delete-fundraising/${_id}`, {
                isNeedDeleteRequest,
                requestCurrentStatus
            });

        } catch (e) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);