import { createSlice } from "@reduxjs/toolkit";
import {
  fetchAllFundraises,
  getFundraiseById,
  getMyFundraises,
  getMyFavouriteFundraises,
  getFundraisesByCategory,
  fetchClosedFundraises,
  addToFavorites,
  deleteFromFavorites
} from "./operations";

const initialState = {
  fundraises: [],
  myFundraises: [],
  favorites: [],
  fundraise: {
    category: {
      typeName: "",
      typeIMG: "",
    },
    owner: {
      name: "",
    },
  },
  loading: false,
  error: null,
  count: 0,
  totalPages: 0,
  currentPage: 1,
};

export const fudraiseSlice = createSlice({
  name: "fundraise",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllFundraises.fulfilled, (state, { payload }) => {
        state.fundraises = payload.data;
        state.loading = false;
        state.totalPages = Math.ceil(payload.count / 8);
        state.count = payload.count;
      })
      .addCase(fetchAllFundraises.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllFundraises.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(getFundraiseById.fulfilled, (state, { payload }) => {
        state.fundraise = payload.data;
        state.loading = false;
      })
      .addCase(getFundraiseById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getFundraiseById.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(getFundraisesByCategory.fulfilled, (state, { payload }) => {
        state.fundraises = payload.data;
        state.loading = false;
      })
      .addCase(getFundraisesByCategory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getFundraisesByCategory.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(fetchClosedFundraises.fulfilled, (state, { payload }) => {
        state.fundraises = payload.data;
        state.loading = false;
      })
      .addCase(fetchClosedFundraises.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchClosedFundraises.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(getMyFundraises.fulfilled, (state, { payload }) => {
        state.myFundraises = payload.data;
        state.loading = false;
      })
      .addCase(getMyFundraises.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getMyFundraises.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(getMyFavouriteFundraises.fulfilled, (state, { payload }) => {
        state.favorites = payload.data;
        state.loading = false;
      })
      .addCase(getMyFavouriteFundraises.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getMyFavouriteFundraises.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(addToFavorites.fulfilled, (state, { payload }) => {
        const favIndex = state.fundraises.findIndex(
          (fundraise) => fundraise._id === payload.data._id
        );
        if (favIndex !== -1) {
          state.fundraises[favIndex] = {
            ...state.fundraises[favIndex],
            favorite: payload.data.favorite,
          };
        }
        state.loading = false;
      })
      .addCase(addToFavorites.pending, (state) => {
        state.error = null;
      })
      .addCase(addToFavorites.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(deleteFromFavorites.fulfilled, (state, { payload }) => {
        const favIndex = state.fundraises.findIndex(
          (fundraise) => fundraise._id === payload.data._id
        );
        if (favIndex !== -1) {
          state.fundraises[favIndex] = {
            ...state.fundraises[favIndex],
            favorite: payload.data.favorite,
          };
        }
        const indInFav = state.favorites.findIndex(
          (fundraise) => fundraise._id === payload.data._id
        );
        if (indInFav !== -1) {
          state.favorites.splice(indInFav, 1);
        }
        state.loading = false;
      })
      .addCase(deleteFromFavorites.pending, (state) => {
        state.error = null;
      })
      .addCase(deleteFromFavorites.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

export const { setFilter } = fudraiseSlice.actions;
export default fudraiseSlice.reducer;
