import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { TextField, Checkbox } from "@mui/material";
import { useState } from "react";
import { closeFundraise } from "../../store/fundraise/operations";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "@splidejs/react-splide/css/sea-green";
import CloseIcon from "@mui/icons-material/Close";

export const CloseFundraiseModal = ({ id }) => {
  const errorToast = (message) =>
    toast.error(message, {
      position: "top-center",
    });
  const successToast = () =>
    toast("Надіслано", {
      position: "top-center",
    });
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [checked, setChecked] = React.useState([false, false]);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    status: "CLOSED",
    reasonForClosing: "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCheck = (index) => (event) => {
    const newChecked = [false, false];
    newChecked[index] = event.target.checked;
    setChecked(newChecked);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const result = dispatch(closeFundraise({ id, body: formData }));
      if (result) {
        setIsLoading(false);
        setFormData({
          status: "",
          reasonForClosing: "",
        });
        successToast();
        setOpen(false);
        document.getElementById("closeForm").reset();
      }
    } catch (error) {
      errorToast(error.response.data.message);
    }
  };

  return (
    <div>
      <button className="button-black" onClick={handleOpen}>
        Повідомити про закриття
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="absolute flex flex-col items-center gap-3 top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2 w-[400] rounded-[8px] p-6 bg-white">
          <h2 id="modal-modal-title" className="pb-6 text-xl">
            Закриваємо збір
          </h2>
          <button
            type="button"
            onClick={handleClose}
            className="absolute top-2 right-3"
          >
            <CloseIcon className="opacity-60 hover:opacity-100 hover:text-[#CA563F] [transition:0.3s_ease]" />
          </button>
          <form
            id="closeForm"
            className="flex flex-col gap-4"
            onSubmit={handleSubmit}
          >
            <div className="flex flex-col items-start gap-2 ">
              <div className="flex justify-start items-center gap-2 w-full">
                <Checkbox
                  checked={checked[0]}
                  label="Checkbox"
                  color="success"
                  size="large"
                  onChange={handleCheck(0)}
                />
                <h2>Сума зібрана, триває закупівля і доставка</h2>
              </div>
              <div className="flex flex-col gap-2 w-full">
                <div className="flex justify-start items-center gap-2 w-full">
                  <Checkbox
                    checked={checked[1]}
                    label="Checkbox"
                    color="success"
                    size="large"
                    onChange={handleCheck(1)}
                  />
                  <h2>Збір втратив актуальність</h2>
                </div>
                <TextField
                  type="text"
                  label="Опишіть деталі"
                  required
                  multiline
                  rows={5}
                  variant="outlined"
                  size="small"
                  name="reasonForClosing"
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className=" flex gap-4 self-center">
              {isLoading ? (
                <button
                  type="submit"
                  className="button-black self-center pointer-events-none"
                >
                  <div className="flex space-x-2 justify-center items-center">
                    <div className="h-4 w-4 bg-grey rounded-full animate-bounce [animation-delay:-0.3s]"></div>
                    <div className="h-4 w-4 bg-grey rounded-full animate-bounce [animation-delay:-0.15s]"></div>
                    <div className="h-4 w-4 bg-grey rounded-full animate-bounce"></div>
                  </div>
                  {isLoading || "Надіслати"}
                </button>
              ) : (
                <button type="submit" className="button-black">
                  Надіслати
                </button>
              )}
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default CloseFundraiseModal;
