import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { TextField } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { verifyMfa } from "../../store/user/operations";
import {
  selectQrCodeUrl,
  selectUserEmail,
  selectSecret,
} from "../../store/user/selectors";

const VerifyMfaModal = () => {
  const dispatch = useDispatch();
  const [isVerifyMfaModalOpen, setIsVerifyMfaModalOpen] = useState(false);
  const [mfaCode, setMfaCode] = useState("");
  const qrCodeUrl = useSelector(selectQrCodeUrl);
  const secret = useSelector(selectSecret);
  const email = useSelector(selectUserEmail);

  const handleOpen = () => setIsVerifyMfaModalOpen(true);
  const handleClose = () => setIsVerifyMfaModalOpen(false);
  const errorToast = (message) =>
    toast.error(message, { position: "top-center" });

  const handleMfaSubmit = async (e) => {
    e.preventDefault();
    try {
      await dispatch(verifyMfa({ mfaCode, email })).unwrap();
      setMfaCode("");
      handleClose();
    } catch (error) {
      errorToast(error.message || "MFA verification failed");
    }
  };

  return (
    <div>
      <p>
        Для доступу до функціоналу волонтеру необхідно увімкнути двофакторну
        автентифікацію
      </p>
      <button className="button-white" onClick={handleOpen}>
        Увімкнути
      </button>

      <Modal open={isVerifyMfaModalOpen} onClose={handleClose}>
        <Box className="absolute flex flex-col items-center gap-3 top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2 w-[300px] rounded-[8px] p-6 bg-white">
          <h2>
            Відскануйте цей код у додатку для двохфакторної авторизації
            (наприклад Google Authenticator)
          </h2>
          {qrCodeUrl && (
            <>
              <img
                src={qrCodeUrl}
                alt="Qr mfa code"
                style={{ width: "100px", height: "100px" }}
              />
              <p>Секретний код (для ручного введення):</p>
              <pre
                style={{
                  padding: "10px",
                  backgroundColor: "#f4f4f4",
                  borderRadius: "5px",
                }}
              >
                {secret}
              </pre>

              <p>
                Або натисніть на посилання, щоб відкрити QR-код у новому вікні:
              </p>
              <a href={qrCodeUrl} target="_blank" rel="noopener noreferrer">
                Відкрити QR-код
              </a>
            </>
          )}
          <form
            id="verifyMfaForm"
            className="flex flex-col gap-4 p-5 w-[300px]"
            onSubmit={handleMfaSubmit}
          >
            <TextField
              type="text"
              label="MFA Code"
              variant="outlined"
              required
              size="small"
              value={mfaCode}
              onChange={(e) => setMfaCode(e.target.value)}
            />
            <button type="submit" className="button-black">
              Submit MFA Code
            </button>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default VerifyMfaModal;
