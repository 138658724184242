import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "react-toastify/dist/ReactToastify.css";
import "@splidejs/react-splide/css/sea-green";
import CloseIcon from "@mui/icons-material/Close";
import mail from "../../assets/icons/EmailLogo.svg";
import { EmailLoginModal, EmailRegisterModal } from ".";

const LoginModal = ({ title, cssClass }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const BASE_URL =
    process.env.REACT_APP_BASE_URL || "https://requesty.com.ua/api/v1";
  // const handleFacebookLogin = () => {
  //   // Це перенаправлення до вашого бекенду для ініціації авторизації через Facebook
  //   window.location.href = `${BASE_URL}/users/auth/facebook`;
  // };
  return (
    <div>
      <button className={cssClass || "button-black p-2"} onClick={handleOpen}>
        {title || "Увійти"}
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="absolute flex flex-col items-center gap-3 top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2 w-[400] rounded-[8px] p-6 bg-white">
          <h1 className="flex self-center text-xl lg:text-3xl p-5">
            Увійдіть за допомогою
          </h1>
          <button
            type="button"
            onClick={handleClose}
            className="absolute top-2 right-3"
          >
            <CloseIcon className="opacity-60 hover:opacity-100 hover:text-[#CA563F] [transition:0.3s_ease]" />
          </button>
          <a
            href={`${BASE_URL}/users/auth/google`}
            className="flex gap-6 items-center justify-center cursor-pointer p-4"
          >
            <img
              width={"40px"}
              src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/google/google-original.svg"
              alt="гуглРеєстрація"
            />
            Google
          </a>
          <div className="flex gap-6 items-center justify-center cursor-pointer p-4">
            <img src={mail} alt="email-icon" />
            <EmailLoginModal />
          </div>
          <div className="flex gap-6 items-center justify-center cursor-pointer p-4">
            <img src={mail} alt="email-icon" />
            <EmailRegisterModal />
          </div>
          {/* <button
            type="button"
            onClick={handleFacebookLogin}
            className="flex gap-6 items-center justify-center cursor-pointer p-10 bg-blue-600 text-white rounded"
          >
            <img
              width={"40px"}
              src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/facebook/facebook-original.svg"
              alt="Facebook Login"
            />
            Facebook
          </button> */}
        </Box>
      </Modal>
    </div>
  );
};

export default LoginModal;
