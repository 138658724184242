import React from "react";
import { Box, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import { VerifyMfaModal } from ".";
import { useSelector } from "react-redux";
import { selectIsMfaEnable } from "../../store/user/selectors";

const VolunteerFormModal = ({
  setShowVolunteerForm,
  handleVolunteerSubmit,
  handleInputChange,
  handleTypeChange,
  isMobile,
  type,
  isLoading,
}) => {
  const isMfaEnable = useSelector(selectIsMfaEnable);

  return (
    <Box className="absolute flex flex-col gap-3 top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2 w-96 lg:w-fit rounded-[8px] p-6 bg-grey">
      {!isMfaEnable ? (
        <VerifyMfaModal />
      ) : (
        <>
          <div>
            <p className="flex text-center text-sm sm:text-sm pt-2">
              Двофакторна аутентифікація успішно увімкнена.
            </p>
          </div>
          <p className="flex text-center text-lg lg:text-3xl pt-2">
            Для доступу до волонтерського функціоналу необхідно додати наступну
            інформацію про себе.
          </p>
          <button
            type="button"
            onClick={() => {
              setShowVolunteerForm(false);
            }}
            className="absolute top-3 right-3"
          >
            <KeyboardReturnIcon className="opacity-60 hover:opacity-100 hover:text-[#CA563F] [transition:0.3s_ease]" />
          </button>

          <form
            id="newVolunteerForm"
            className="flex flex-col gap-4"
            onSubmit={handleVolunteerSubmit}
          >
            <TextField
              type="text"
              label="ПІБ"
              variant="outlined"
              required
              size="small"
              name="fullName"
              helperText="Приклад: Тарас Григорович Шевченко"
              onChange={handleInputChange}
              disabled={!isMfaEnable}
            />
            <TextField
              type="phone"
              label="Номер телефону"
              variant="outlined"
              required
              size="small"
              name="phone"
              helperText="Приклад: +380973332211"
              onChange={handleInputChange}
              disabled={!isMfaEnable}
            />
            <TextField
              type="text"
              label="Посилання facebook"
              variant="outlined"
              required
              size="small"
              name="fbUrl"
              helperText="Приклад: https://www.facebook.com/nikname"
              onChange={handleInputChange}
              disabled={!isMfaEnable}
            />
            <TextField
              type="text"
              label="Посилання instagram"
              variant="outlined"
              required
              size="small"
              name="instaUrl"
              helperText="Приклад: https://www.instagram.com/nikname"
              onChange={handleInputChange}
              disabled={!isMfaEnable}
            />

            <div className="flex flex-col items-center gap-4 px-6 bg-grey rounded-md">
              <h2>Напрямки діяльності</h2>
              <ToggleButtonGroup
                value={type}
                aria-label="Platform"
                onChange={handleTypeChange}
                size="medium"
                orientation={isMobile ? "vertical" : "horizontal"}
                disabled={!isMfaEnable}
              >
                <ToggleButton name={"specialization"} value="Дрони">
                  Дрони
                </ToggleButton>
                <ToggleButton name={"specialization"} value="Транспорт">
                  Транспорт
                </ToggleButton>
                <ToggleButton name={"specialization"} value="Обладнання">
                  Обладнання
                </ToggleButton>
                <ToggleButton name={"specialization"} value="Провізія">
                  Провізія
                </ToggleButton>
                <ToggleButton name={"specialization"} value="Медицина">
                  Медицина
                </ToggleButton>
                <ToggleButton name={"specialization"} value="Реабілітація">
                  Реабілітація
                </ToggleButton>
                <ToggleButton name={"specialization"} value="Інше">
                  Інше
                </ToggleButton>
              </ToggleButtonGroup>
            </div>

            <div className="self-center">
              {isLoading ? (
                <button
                  type="submit"
                  className="button-black self-center pointer-events-none"
                  disabled
                >
                  <div className="flex space-x-2 justify-center items-center">
                    <div className="h-4 w-4 bg-grey rounded-full animate-bounce [animation-delay:-0.3s]"></div>
                    <div className="h-4 w-4 bg-grey rounded-full animate-bounce [animation-delay:-0.15s]"></div>
                    <div className="h-4 w-4 bg-grey rounded-full animate-bounce"></div>
                  </div>
                  {isLoading || "Надіслати"}
                </button>
              ) : (
                <button
                  type="submit"
                  className="button-black"
                  disabled={!isMfaEnable}
                >
                  Надіслати
                </button>
              )}
            </div>
          </form>
        </>
      )}
    </Box>
  );
};

export default VolunteerFormModal;
