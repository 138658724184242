export const selectIsLoggedIn = (state) => state.auth.isLoggedIn;
export const selectIsRefreshing = (state) => state.auth.isRefreshing;
export const selectCurrentUser = (state) => state.auth.user;
export const selectUserEmail = (state) => state.auth.user.email;
export const selectCurrentUserRole = (state) => state.auth.user.role;
export const selectMessage = (state) => state.auth.message;
export const selectQrCodeUrl = (state) => state.auth.user.qrCodeUrl;
export const selectSecret = (state) => state.auth.user.secret;
export const selectIsMfaEnable = (state) => state.auth.user.isMfaEnable;
export const selectMfacodes = (state) => state.auth.user.recoveryCodes;
export const selectIsNewUser = (state) => state.auth.user.isNewUser;
