import instance from '../user/operations';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchApprovedVolunteers = createAsyncThunk(
    "volunteers/approved",
    async ({ query, id, page }, { rejectWithValue, getState }) => {
        try {
            const accessToken = localStorage.getItem('accessToken')

            if (!accessToken) {
                return rejectWithValue("Unable to fetch user");
            }
            const result = await instance.get(`volunteers/approved?page=${page}&limit=10&specialization=${id}&name=${query}`);
            return result.data.data;
        } catch ({ response }) {
            const { status, data } = response;
            const error = {
                status,
                message: data.message,
            };
            return rejectWithValue(error);
        }
    }
);