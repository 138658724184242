import { createSlice } from '@reduxjs/toolkit'
import { fetchPetitions } from './operations';

const initialState = {
    petitions: [],
    loading: false,
    error: null,
    count: 0,
    totalPages: 0,
    currentPage: 1,
}

export const petitionsSlice = createSlice({
    name: 'petitions',
    initialState,
    extraReducers: builder => {
        builder
            .addCase(fetchPetitions.fulfilled, (state, { payload }) => {
                state.petitions = payload.data;
                state.loading = false;
                state.totalPages = Math.ceil(payload.count / 8);
                state.count = payload.count
            })
            .addCase(fetchPetitions.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchPetitions.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
            })
    },
})

export default petitionsSlice.reducer