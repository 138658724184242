import { push as Menu } from "react-burger-menu";
import "./BurgerMenu.css";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useState } from "react";
import {
  selectIsLoggedIn,
  selectCurrentUser,
} from "../../store/user/selectors";
import DropDown from "../../components/profileDropDown";
import { LoginModal } from "../modal";

const BurgerMenu = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const { role, id } = useSelector(selectCurrentUser);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  function refreshPage() {
    window.location.reload(false);
  }

  const handleMenuClick = () => {
    setIsMenuOpen(false);
  };

  const menuOpen = () => {
    setIsMenuOpen(true);
  };
  const menuClose = () => {
    setIsMenuOpen(false);
  };

  const uppPageHandler = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <Menu
      pageWrapId={"page-wrap"}
      outerContainerId={"outer-container"}
      right
      isOpen={isMenuOpen}
      onOpen={menuOpen}
      onClose={menuClose}
    >
      <ul
        className="flex gap-8 pr-8 items-center border-r-2 border-grey"
        onClick={handleMenuClick}
      >
        <NavLink
          className={"menu-item"}
          onClick={uppPageHandler}
          to="/fundraises"
        >
          Збори
        </NavLink>
        <NavLink className={"menu-item"} onClick={uppPageHandler} to="/funds">
          Фонди
        </NavLink>
        <NavLink
          className={"menu-item"}
          onClick={uppPageHandler}
          to="/petitions"
        >
          Петиції
        </NavLink>
        <NavLink
          className={"menu-item"}
          onClick={uppPageHandler}
          to="/initiatives"
        >
          Ініціативи
        </NavLink>
      </ul>
      <ul className="flex gap-8 pl-8 items-center" onClick={handleMenuClick}>
        {isLoggedIn || <LoginModal />}
        {isLoggedIn && (
          <>
            {role === "USER" && (
              <NavLink className={"menu-item"} to="/favorites">
                Збережене
              </NavLink>
            )}
            {role === "DEFENDER" && (
              <NavLink
                className={"menu-item"}
                onClick={uppPageHandler}
                to="/my-requests"
              >
                Мої запити
              </NavLink>
            )}
            {role === "VOLUNTEER" && (
              <>
                <NavLink
                  className={"menu-item"}
                  onClick={uppPageHandler}
                  to="/volunteers"
                >
                  Волонтери
                </NavLink>
                <NavLink
                  className={"menu-item"}
                  onClick={uppPageHandler}
                  to="/requests"
                >
                  Запити
                </NavLink>
                <NavLink
                  className={"menu-item"}
                  onClick={uppPageHandler}
                  to="/my-fundraises"
                >
                  Мої збори
                </NavLink>
              </>
            )}
            {role === "ADMIN" && (
              <>
                <NavLink
                  className={"menu-item"}
                  onClick={uppPageHandler}
                  to="/admin"
                >
                  Кабінет
                </NavLink>
              </>
            )}
            {role === "ADMIN" && !isMobile ? (
              <DropDown />
            ) : (
              <ul onClick={handleMenuClick}>
                <NavLink
                  className={"menu-item"}
                  to={`/profile/${id}`}
                  onClick={uppPageHandler}
                >
                  Профіль
                </NavLink>
                <NavLink
                  className={"menu-item"}
                  to="/users/logout"
                  onClick={refreshPage}
                >
                  Вийти
                </NavLink>
              </ul>
            )}
          </>
        )}
      </ul>
    </Menu>
  );
};

export default BurgerMenu;
