import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { TextField } from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addInitiative } from "../../store/initiatives/operations";
import "@splidejs/react-splide/css/sea-green";
import CloseIcon from "@mui/icons-material/Close";

const AddInitiativeModal = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isLoading, setIsLoading] = useState(false);

  const errorToast = (message) =>
    toast.error(message, {
      position: "top-center",
    });
  const successToast = () =>
    toast(
      "Ініціатива успішно додана. Після перевірки вона зявиться на сайті. Слава Україні!",
      {
        position: "top-center",
      }
    );

  const [formData, setFormData] = useState({
    initiativeTitle: "",
    initiativeDescr: "",
    initiativeLink: "",
    initiativeOwner: "",
    initiativeOwnerMail: "",
    initiativeOwnerPhone: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const result = await addInitiative(formData);
      if (result) {
        successToast();
        setFormData({
          initiativeTitle: "",
          initiativeDescr: "",
          initiativeLink: "",
          initiativeOwner: "",
          initiativeOwnerMail: "",
          initiativeOwnerPhone: "",
        });
        document.getElementById("initform").reset();
      }
    } catch (error) {
      setIsLoading(false);
      errorToast(error.response.data.message);
    }
  };

  return (
    <div>
      <button className="button-black" onClick={handleOpen}>
        Додати ініціативу
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="absolute flex flex-col items-center gap-3 top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2 w-[400] rounded-[8px] p-6 bg-white">
          <h1 className="flex self-center text-xl lg:text-5xl">
            Додати ініціативу
          </h1>
          <button
            type="button"
            onClick={handleClose}
            className="absolute top-2 right-3"
          >
            <CloseIcon className="opacity-60 hover:opacity-100 hover:text-[#CA563F] [transition:0.3s_ease]" />
          </button>
          <form
            id="initform"
            className="flex flex-col gap-4 p-5 w-[300px] md:w-[500px] lg:w-[1000px]"
            onSubmit={handleSubmit}
          >
            <TextField
              type="text"
              label="Назва"
              variant="outlined"
              required
              size="small"
              name="initiativeTitle"
              onChange={handleInputChange}
            />
            <TextField
              type="text"
              label="Опис"
              variant="outlined"
              required
              size="small"
              name="initiativeDescr"
              onChange={handleInputChange}
            />
            <TextField
              type="text"
              label="Посилання"
              variant="outlined"
              required
              size="small"
              name="initiativeLink"
              helperText="соц. мережі або сайт"
              onChange={handleInputChange}
            />
            <TextField
              type="text"
              label="Власник ініціативи"
              variant="outlined"
              required
              size="small"
              name="initiativeOwner"
              helperText="формат: ПІБ"
              onChange={handleInputChange}
            />
            <TextField
              type="text"
              label="Номер телефону"
              variant="outlined"
              required
              size="small"
              name="initiativeOwnerPhone"
              helperText="формат: +380630000000"
              onChange={handleInputChange}
            />
            <div className="self-center">
              {isLoading ? (
                <button
                  type="submit"
                  className="button-black self-center pointer-events-none"
                >
                  <div className="flex space-x-2 justify-center items-center">
                    <div className="h-4 w-4 bg-grey rounded-full animate-bounce [animation-delay:-0.3s]"></div>
                    <div className="h-4 w-4 bg-grey rounded-full animate-bounce [animation-delay:-0.15s]"></div>
                    <div className="h-4 w-4 bg-grey rounded-full animate-bounce"></div>
                  </div>
                  {isLoading || "Надіслати"}
                </button>
              ) : (
                <button type="submit" className="button-black">
                  Надіслати
                </button>
              )}
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default AddInitiativeModal;
