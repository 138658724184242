import * as React from "react";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import { TextField } from "@mui/material";
import { useState } from "react";

import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "@splidejs/react-splide/css/sea-green";
import CloseIcon from "@mui/icons-material/Close";

import { emailLogin, mfaVerify } from "../../store/user/operations";

const EmailLoginModal = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [mfaToken, setMfaToken] = useState(null);
  const [mfaCode, setMfaCode] = useState("");
  const [isMfaModalOpen, setIsMfaModalOpen] = useState(false);

  const errorToast = (message) =>
    toast.error(message, {
      position: "top-center",
    });

  const [formDataValues, setFormDataValues] = useState({
    email: "",
    password: "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormDataValues((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await dispatch(emailLogin(formDataValues)).unwrap();
      if (result) {
        setFormDataValues({
          email: "",
          password: "",
        });
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    } catch (error) {
      console.log("error", error);

      if (error.status === 403 && error.message === "MFA required") {
        setMfaToken(error.data);
        setIsMfaModalOpen(true);
      } else {
        errorToast(error.message || "An error occurred");
      }
    }
  };

  const handleMfaSubmit = async () => {
    try {
      dispatch(mfaVerify({ mfaToken, mfaCode }));
      setIsMfaModalOpen(false);
      setFormDataValues({
        email: "",
        password: "",
      });
      document.getElementById("emailLoginForm").reset();
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } catch (error) {
      errorToast(error.response?.data?.message || "MFA verification failed");
    }
  };

  return (
    <div>
      <button className="button-white" onClick={handleOpen}>
        Login with email
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="absolute flex flex-col items-center gap-3 top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2 w-[400] rounded-[8px] p-6 bg-white">
          <h1 className="flex self-center text-xl lg:text-5xl">Увійти</h1>
          <button
            type="button"
            onClick={handleClose}
            className="absolute top-2 right-3"
          >
            <CloseIcon className="opacity-60 hover:opacity-100 hover:text-[#CA563F] [transition:0.3s_ease]" />
          </button>
          <form
            id="emailLoginForm"
            className="flex flex-col gap-4 p-5 w-[300px] md:w-[500px] lg:w-[1000px]"
            onSubmit={handleSubmit}
          >
            <TextField
              type="text"
              label="Ваш email"
              variant="outlined"
              required
              size="small"
              name="email"
              onChange={handleInputChange}
            />
            <TextField
              type="text"
              label="Ваш пароль"
              variant="outlined"
              required
              size="small"
              name="password"
              onChange={handleInputChange}
            />
            <div className="self-center">
              <button type="submit" className="button-black">
                Увійти
              </button>
            </div>
          </form>
        </Box>
      </Modal>
      <Modal open={isMfaModalOpen} onClose={() => setIsMfaModalOpen(false)}>
        <Box className="absolute flex flex-col items-center gap-3 top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2 w-[300px] rounded-[8px] p-6 bg-white">
          <h2>Enter MFA Code</h2>
          <TextField
            type="text"
            label="MFA Code"
            variant="outlined"
            required
            size="small"
            value={mfaCode}
            onChange={(e) => setMfaCode(e.target.value)}
          />
          <button onClick={handleMfaSubmit} className="button-black">
            Submit MFA Code
          </button>
        </Box>
      </Modal>
    </div>
  );
};

export default EmailLoginModal;
