import { createSlice } from '@reduxjs/toolkit'
import { fetchInitiatives } from './operations';

const initialState = {
    initiatives: [],
    loading: false,
    error: null,
    count: 0,
    totalPages: 0,
    currentPage: 1,
}

export const initiativesSlice = createSlice({
    name: 'initiatives',
    initialState,
    extraReducers: builder => {
        builder
            .addCase(fetchInitiatives.fulfilled, (state, { payload }) => {
                state.initiatives = payload.data;
                state.loading = false;
                state.totalPages = Math.ceil(payload.count / 8);
                state.count = payload.count
            })
            .addCase(fetchInitiatives.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchInitiatives.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
            })
    },
})

export default initiativesSlice.reducer