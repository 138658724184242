import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const BASE_URL =
  process.env.REACT_APP_BASE_URL || "https://requesty.com.ua/api/v1";

export const instance = axios.create({
  baseURL: BASE_URL,
});

const setToken = (token) => {
  if (token) {
    return (instance.defaults.headers.common.authorization = `Bearer ${token}`);
  }
  instance.defaults.headers.common.authorization = "";
};

const clearToken = () => {
  instance.defaults.headers.common.authorization = null;
};

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const storedAccessToken = localStorage.getItem("accessToken");
    if (error.response.status === 401) {
      const storedRefreshToken = localStorage.getItem("refreshToken");
      try {
        const { data } = await instance.post("/users/refresh", {
          refreshToken: storedRefreshToken,
        });

        setToken(data.data.accessToken);

        localStorage.setItem("accessToken", data.data.accessToken);
        localStorage.setItem("refreshToken", data.data.refreshToken);

        const updatedConfig = { ...error.config };

        updatedConfig.headers.Authorization = `Bearer ${data.data.accessToken}`;

        if (error.config.url !== "/users/current-user") {
          return instance(error.config);
        }
        return instance(updatedConfig);
      } catch (error) {
        return Promise.reject(error);
      }
    }
    if (error.response.status === 403 && storedAccessToken) {
      localStorage.setItem("accessToken", "");
      localStorage.setItem("refreshToken", "");
    }
    return Promise.reject(error);
  }
);

export default instance;

export const currentUser = createAsyncThunk(
  "user/current",
  async (_, { rejectWithValue, getState }) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      setToken(accessToken);
      if (!accessToken) {
        return rejectWithValue("Unable to fetch user");
      }
      const result = await instance.get("/users/current-user");
      return result.data.data;
    } catch ({ response }) {
      const { status, data } = response;
      const error = {
        status,
        message: data.message,
      };
      return rejectWithValue(error);
    }
  }
);
export const updateUser = createAsyncThunk(
  "user/update",
  async (body, { rejectWithValue, getState }) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      setToken(accessToken);
      if (!accessToken) {
        return rejectWithValue("Unable to fetch user");
      }
      const result = await instance.patch("/users/update-user", body);
      return result.data.data;
    } catch ({ response }) {
      const { status, data } = response;
      const error = {
        status,
        message: data.message,
      };
      return rejectWithValue(error);
    }
  }
);
export const upgradeUserToDefender = createAsyncThunk(
  "user/upgradeToDefender",
  async (body, { rejectWithValue, getState }) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      setToken(accessToken);
      if (!accessToken) {
        return rejectWithValue("Unable to fetch user");
      }
      const result = await instance.patch("/users/role", body);
      return result.data.data;
    } catch ({ response }) {
      const { status, data } = response;
      const error = {
        status,
        message: data.message,
      };
      return rejectWithValue(error);
    }
  }
);
export const upgradeUserToVolunteer = createAsyncThunk(
  "user/upgradeToVolunteer",
  async (body, { rejectWithValue, getState }) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      setToken(accessToken);
      if (!accessToken) {
        return rejectWithValue("Unable to fetch user");
      }
      const result = await instance.patch("/volunteers/new-volunteer", body);
      return result.data.data;
    } catch ({ response }) {
      const { status, data } = response;
      const error = {
        status,
        message: data.message,
      };
      return rejectWithValue(error);
    }
  }
);
export const updateVolunteer = createAsyncThunk(
  "user/updateVolunteer",
  async (body, { rejectWithValue, getState }) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      setToken(accessToken);
      if (!accessToken) {
        return rejectWithValue("Unable to fetch user");
      }
      const result = await instance.patch("/volunteers/update-volunteer", body);
      return result.data.data;
    } catch ({ response }) {
      const { status, data } = response;
      const error = {
        status,
        message: data.message,
      };
      return rejectWithValue(error);
    }
  }
);

export const logOut = createAsyncThunk("user/logout", async (_, thunkAPI) => {
  try {
    await instance.post("/users/logout");
    document.cookie = `accessToken=""; path=/;`;
    document.cookie = `refreshToken=""; path=/;`;
    clearToken();
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const addFeedback = createAsyncThunk(
  "user/feedback",
  async (body, { rejectWithValue, getState }) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      setToken(accessToken);
      if (!accessToken) {
        return rejectWithValue("Unable to fetch user");
      }
      const result = await instance.post("/users/feedback", body);
      return result.data.data;
    } catch ({ response }) {
      const { status, data } = response;
      const error = {
        status,
        message: data.message,
      };
      return rejectWithValue(error);
    }
  }
);

export const deleteAccount = createAsyncThunk(
  "user/deleteAccount",
  async (body, { rejectWithValue, getState }) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      setToken(accessToken);
      if (!accessToken) {
        return rejectWithValue("Unable to fetch user");
      }
      const result = await instance.post("/users/delete-account", body);
      return result.data.data;
    } catch ({ response }) {
      const { status, data } = response;
      console.log("status:", status);
      console.log("data:", data);
      const error = {
        status,
        message: data.message,
      };
      return rejectWithValue(error);
    }
  }
);

export const emailRegister = createAsyncThunk(
  "user/emailRegister",
  async (body, { rejectWithValue, getState }) => {
    try {
      const result = await instance.post("/users/register", body);
      localStorage.setItem("accessToken", result.data.data.accessToken);
      localStorage.setItem("refreshToken", result.data.data.refreshToken);
      return result.data.data;
    } catch ({ response }) {
      console.log("response", response);
      const { status, data } = response;
      const error = {
        status,
        message: data.message,
        data: data.data,
      };
      console.log("error", error);
      return rejectWithValue(error);
    }
  }
);

export const emailLogin = createAsyncThunk(
  "user/emailLogin",
  async (body, { rejectWithValue, getState }) => {
    try {
      const result = await instance.post("/users/login", body);
      localStorage.setItem("accessToken", result.data.data.accessToken);
      localStorage.setItem("refreshToken", result.data.data.refreshToken);
      return result.data.data;
    } catch (e) {
      console.log("e", e);
      const { status, data } = e;
      const error = {
        status,
        message: data.message,
        data: data.data,
      };
      console.log("error", error);
      return rejectWithValue(error);
    }
  }
);

export const mfaVerify = createAsyncThunk(
  "user/mfaVerify",
  async ({ mfaToken, mfaCode }, { rejectWithValue, getState }) => {
    try {
      const result = await instance.post(
        "/users/mfa/verify",
        {
          code: mfaCode,
        },
        {
          headers: {
            "MFA-Token": mfaToken,
          },
        }
      );
      localStorage.setItem("accessToken", result.data.data.accessToken);
      localStorage.setItem("refreshToken", result.data.data.refreshToken);
      return result.data.data;
    } catch ({ response }) {
      console.log("response", response);
      const { status, data } = response;
      const error = {
        status,
        message: data.message,
      };
      return rejectWithValue(error);
    }
  }
);

export const mfaGenerate = createAsyncThunk(
  "user/mfaGenerate",
  async (_, { rejectWithValue, getState }) => {
    const accessToken = localStorage.getItem("accessToken");
    try {
      const result = await instance.post("/mfa/generate", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return result.data.data;
    } catch ({ response }) {
      console.log("response", response);
      const { status, data } = response;
      const error = {
        status,
        message: data.message,
      };
      return rejectWithValue(error);
    }
  }
);

export const verifyMfa = createAsyncThunk(
  "user/verifyMfa",
  async ({ mfaCode, email }, { rejectWithValue, getState }) => {
    const accessToken = localStorage.getItem("accessToken");
    try {
      const result = await instance.post(
        `/mfa/verify/${email}`,
        {
          code: mfaCode,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return result.data;
    } catch ({ response }) {
      const { status, data } = response;
      const error = {
        status,
        message: data.message,
      };
      return rejectWithValue(error);
    }
  }
);
