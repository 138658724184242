import instance from '../user/operations';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchAllRequests = createAsyncThunk(
    'requests/fetchAll',
    async (page, thunkApi) => {
        try {
            const response = await instance.get(`/requests/all-active?page=${page}&limit=8`);
            return response.data;
        } catch (e) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);
export const getRequestById = createAsyncThunk(
    'requests/getRequestById',
    async (id, thunkApi) => {
        try {
            const response = await instance.get(`/requests/one/${id}`);
            return response.data;
        } catch (e) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);
export const getRequestByCategory = createAsyncThunk(
    'requests/getRequestByCategory',
    async ({ page, id }, thunkApi) => {
        try {
            const response = await instance.get(`/requests/active?page=${page}&limit=8&category=${id}`);
            return response.data;
        } catch (e) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const fetchMyRequests = createAsyncThunk(
    'requests/fetchMyRequests',
    async (page, thunkApi) => {
        try {
            const response = await instance.get(`/requests/my-requests?page=${page}&limit=10`);
            return response.data;
        } catch (e) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);
export const getRequestsInProgress = createAsyncThunk(
    'requests/getInProgress',
    async (page, thunkApi) => {
        try {
            const response = await instance.get(`/requests/requests-in-process?page=${page}&limit=10`);
            return response.data;
        } catch (e) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);
export const addVolunteer = createAsyncThunk(
    'requests/addVolunteer',
    async (id, thunkApi) => {
        try {
            const response = await instance.patch(`/requests/add-volunteer/${id}`);
            return response.data;
        } catch (e) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);
export const approveVolunteer = createAsyncThunk(
    'requests/approveVolunteer',
    async (id, thunkApi) => {
        try {
            const response = await instance.patch(`/requests/approved-volunteer/${id}`);
            return response.data;
        } catch (e) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);
export const unapproveVolunteer = createAsyncThunk(
    'requests/unapproveVolunteer',
    async (id, thunkApi) => {
        try {
            const response = await instance.patch(`/requests/unapproved-volunteer/${id}`);
            return response.data;
        } catch (e) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);
export const deleteRequest = createAsyncThunk(
    'requests/deleteRequest',
    async (id, thunkApi) => {
        const accessToken = localStorage.getItem("accessToken");
        try {
            const response = await instance.delete(`/requests/${id}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            return response.data;
        } catch (e) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

// export const deleteRequest = async (id) => {
//     const accessToken = localStorage.getItem("accessToken");
//     try {
//         const result = await instance.delete(`/requests/${id}`, {
//             headers: {
//                 Authorization: `Bearer ${accessToken}`,
//             },
//         });
//         return result.data;
//     } catch (error) {
//         console.error(error.response.data.message);
//         throw error;
//     }
// };

export const addRequest = async (body) => {
    const accessToken = localStorage.getItem("accessToken");
    try {
        const result = await instance.post(`/requests/add-request`, body, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
        return result.data;
    } catch (error) {
        console.error(error.response.data.message);
        throw error;
    }
};
