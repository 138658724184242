import { createSlice } from '@reduxjs/toolkit'
import { fetchMyRequests, fetchAllRequests, getRequestById, getRequestsInProgress, deleteRequest, getRequestByCategory, addVolunteer, approveVolunteer } from './operations';

const initialState = {
    requests: [],
    myRequests: [],
    request: {
        category: {
            typeName: "",
        },
        requestOwner: {
            name: "",
        },
        requestCurrentStatus: "",
        addedVolunteerEmail: "",
        addedVolunteerName: "",
        addedVolunteerPhone: "",

    },
    loading: false,
    error: null,
    count: 0,
    totalPages: 0,
    currentPage: 1,
}

export const requestsSlice = createSlice({
    name: 'requests',
    initialState,
    extraReducers: builder => {
        builder
            .addCase(fetchMyRequests.fulfilled, (state, { payload }) => {
                state.requests = payload.data;
                state.loading = false;
                state.totalPages = Math.ceil(payload.count / 10);
                state.count = payload.count
            })
            .addCase(fetchMyRequests.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchMyRequests.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
            })
            .addCase(fetchAllRequests.fulfilled, (state, { payload }) => {
                state.requests = payload.data;
                state.loading = false;
                state.totalPages = Math.ceil(payload.count / 8);
                state.count = payload.count
            })
            .addCase(fetchAllRequests.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchAllRequests.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
            })
            .addCase(getRequestById.fulfilled, (state, { payload }) => {
                state.request = payload.data;
                state.loading = false;
                state.count = payload.count
            })
            .addCase(getRequestById.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getRequestById.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
            })
            .addCase(getRequestByCategory.fulfilled, (state, { payload }) => {
                state.request = payload.data;
                state.loading = false;
                state.count = payload.count
            })
            .addCase(getRequestByCategory.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getRequestByCategory.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
            })
            .addCase(getRequestsInProgress.fulfilled, (state, { payload }) => {
                state.myRequests = payload.data;
                state.loading = false;
                state.count = payload.count
            })
            .addCase(getRequestsInProgress.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getRequestsInProgress.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
            })
            .addCase(deleteRequest.fulfilled, (state, { action }) => {
                state.myRequests = state.myRequests.filter((request) => request._id !== action.payload);
                state.loading = false;
            })
            .addCase(deleteRequest.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(deleteRequest.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
            })
            .addCase(approveVolunteer.fulfilled, (state, { payload }) => {
                state.request = payload.data;
                state.loading = false;
            })
            .addCase(approveVolunteer.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(approveVolunteer.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
            })
            .addCase(addVolunteer.fulfilled, (state, { payload }) => {
                state.requests = payload.data;
                state.loading = false;
            })
            .addCase(addVolunteer.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(addVolunteer.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
            })
    },
})

export default requestsSlice.reducer