import { createSlice } from '@reduxjs/toolkit'
import { fetchCategories } from './operations';

const initialState = {
    categories: [],
    loading: false,
    error: null,
}

export const categoriesSlice = createSlice({
    name: 'category',
    initialState,
    extraReducers: builder => {
        builder
            .addCase(fetchCategories.fulfilled, (state, { payload }) => {
                state.categories = payload;
                state.loading = false;
            })
            .addCase(fetchCategories.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCategories.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
            })
    },
})

export default categoriesSlice.reducer