import instance from '../user/operations';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchCategories = createAsyncThunk(
    'categories/fetchAll',
    async ( thunkApi) => {
        try {
            const response = await instance.get(`/request-types/active`);
            return response.data.data;
        } catch (e) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

