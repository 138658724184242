import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useMediaQuery } from "react-responsive";
import { Checkbox } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "@splidejs/react-splide/css/sea-green";
import { useEffect } from "react";
import {
  mfaGenerate,
  upgradeUserToDefender,
  upgradeUserToVolunteer,
} from "../../store/user/operations";
import { VolunteerFormModal } from ".";
import { selectIsMfaEnable, selectIsNewUser } from "../../store/user/selectors";

const FirstLoginModal = () => {
  const dispatch = useDispatch();
  const isMfaEnable = useSelector(selectIsMfaEnable);
  const [open, setOpen] = useState(false);
  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const isNewUser = useSelector(selectIsNewUser);
  console.log("isNewUser", isNewUser);

  const [checked, setChecked] = React.useState([false, false, false]);
  const [showVolunteerForm, setShowVolunteerForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [type, setTypes] = useState([]);
  const [volunteerInfo, setVolunteerInfo] = useState({
    specialization: [],
    fullName: "",
    phone: "",
    fbUrl: "",
    instaUrl: "",
  });
  const [formData, setFormData] = useState({
    role: "",
  });

  const errorToast = (message) =>
    toast.error(message, {
      position: "top-center",
    });
  const successToast = (message) =>
    toast(message, {
      position: "top-center",
    });

  const handleTypeChange = (event, newTypes) => {
    setTypes(newTypes);
    setVolunteerInfo((prevData) => ({
      ...prevData,
      specialization: newTypes,
    }));
  };

  useEffect(() => {
    if (isNewUser) {
      setOpen(true);
    }
  }, [isNewUser]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setVolunteerInfo((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleCheck = (index) => (event) => {
    const newChecked = [false, false, false];
    newChecked[index] = event.target.checked;
    setChecked(newChecked);

    const roles = ["DEFENDER", "VOLUNTEER", "USER"];
    setFormData((prevData) => ({
      ...prevData,
      role: event.target.checked ? roles[index] : "",
    }));
  };

  const handleVolunteerSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const result = dispatch(upgradeUserToVolunteer(volunteerInfo));
      if (result) {
        successToast(
          "Супер! Ми все перевіримо і як що все добре ви зможете волонтерити!)"
        );
        setFormData({
          specialization: [],
          fullName: "",
          phone: "",
          fbUrl: "",
          instaUrl: "",
        });
        setOpen(false);
      }
    } catch (error) {
      console.log("error:", error);
      setIsLoading(false);
      errorToast(error.message);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (formData.role === "DEFENDER") {
        setFormData(formData);
        successToast("Чудово, тепер ви можете створювати запити");
        setOpen(false);
      } else if (formData.role === "USER") {
        setFormData(formData);
        successToast(
          "Гаразд, тепер ви можете зберігати збори щоб стежити за ними"
        );
        setOpen(false);
      } else if (formData.role === "VOLUNTEER") {
        if (!isMfaEnable) {
          dispatch(mfaGenerate());
        }
        setShowVolunteerForm(true);
      }
      const result = dispatch(upgradeUserToDefender(formData));
      if (result) {
        setFormData({
          isNewUser: false,
          role: "",
        });
      }
    } catch (error) {
      alert(error);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      disableEscapeKeyDown
      className="overflow-y-scroll"
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {!showVolunteerForm ? (
        <Box className="absolute flex flex-col items-center gap-3 top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2 w-96 lg:w-fit rounded-[8px] p-6 bg-grey">
          <h2 className="flex text-center text-xl lg:text-3xl pt-5">
            Вітаємо на платформі Реквесті!
          </h2>
          <p className="flex self-center text-xl lg:text-3xl">
            Хто до нас завітав?
          </p>
          <form
            id="firstLoginForm"
            className="flex flex-col gap-4"
            onSubmit={handleSubmit}
          >
            <div className="flex flex-col items-start gap-2 ">
              <div className="flex justify-start items-center gap-2 w-full">
                <Checkbox
                  checked={checked[0]}
                  label="Checkbox"
                  color="success"
                  size="large"
                  onChange={handleCheck(0)}
                />
                <p>Я військовий</p>
              </div>
              <div className="flex justify-start items-center gap-2 w-full">
                <Checkbox
                  checked={checked[1]}
                  label="Checkbox"
                  color="success"
                  size="large"
                  onChange={handleCheck(1)}
                />
                <p>Я волонтер</p>
              </div>
              <div className="flex justify-start items-center gap-2 w-full">
                <Checkbox
                  checked={checked[2]}
                  label="Checkbox"
                  color="success"
                  size="large"
                  onChange={handleCheck(2)}
                />
                <p>Я донатер</p>
              </div>
            </div>
            <button type="submit" className="button-white">
              Підтвердити
            </button>
          </form>
        </Box>
      ) : (
        <VolunteerFormModal
          setShowVolunteerForm={setShowVolunteerForm}
          handleVolunteerSubmit={handleVolunteerSubmit}
          handleInputChange={handleInputChange}
          handleTypeChange={handleTypeChange}
          isMobile={isMobile}
          type={type}
          isLoading={isLoading}
        />
      )}
    </Modal>
  );
};

export default FirstLoginModal;
